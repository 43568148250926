import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ScrollPercentage } from 'react-scroll-percentage';
import {
  LargeImg,
  LineBreak,
  Paragraph,
  SubSectionH2,
  SubSectionH3,
  SectionTitle,
  Note,
  LinksToLearnMore,
  SEO
} from 'components';
import { graphql, Link, navigate } from 'gatsby';
import { NerveIcon, HeartIcon, BrainIcon, MicroscopeIcon, DnaIcon, RibcageIcon } from 'images';

const SymptomsAndDiagnosis = ({
  data,
  checkIfSectionIsInView,
  viewportWidth,
  updateActiveAnchor,
  scrollToAnchor,
  handleCarousel,
  location,
  populateAsideWithSectionTitlesFromCurrentPage
}) => {
  useEffect(() => {
    if (viewportWidth >= 768) {
      populateAsideWithSectionTitlesFromCurrentPage();
    }
  }, [viewportWidth]);

  return (
    <section>
      <SEO
        pageSpecificTitle="Symptoms And Diagnosis"
        pageSpecificDescription="Learn about the symptoms of hATTR amyloidosis and how healthcare professionals diagnose it."
        pathname={location.pathname}
      />
      <ScrollPercentage
        as="div"
        onChange={(percentage) => checkIfSectionIsInView(percentage, '#about-amyloidosis')}>
        <SectionTitle id="about-amyloidosis">About Amyloidosis</SectionTitle>
      </ScrollPercentage>
      <SubSectionH2>General Information</SubSectionH2>
      <Paragraph>
        Amyloidosis is the name for <b>a group of rare, serious conditions</b> caused by a build-up
        of an abnormal <b>protein called amyloid</b> in organs and tissues throughout the body.
      </Paragraph>
      <LineBreak />
      <Paragraph>
        This build-up of amyloid proteins (deposits) can make it difficult for the organs and
        tissues to work properly. Amyloidosis can affect different organs in different people, and
        there are different types of amyloid. hATTR is classed as one form of amyloidosis.
      </Paragraph>
      <LineBreak customHeight="3.55em" />
      <LargeImg
        sources={[
          data.churchOnMistyMorningMobile.childImageSharp.fluid,
          data.churchOnMistyMorning.childImageSharp.fluid
        ]}
        alt={data.churchOnMistyMorning.name}
        caption="Image - Brooke Park, Derry"
      />
      <LineBreak customHeight="4.325em" />
      <SubSectionH3>About hATTR Amyloidosis</SubSectionH3>
      <Paragraph>
        hATTR amyloidosis is caused by a <b>gene change</b> (mutation){' '}
        <b>that affects the function of a protein in the blood called transthyretin</b> (TTR). This
        protein is made primarily in the liver. The condition affects multiple organs - most
        commonly the heart, the nervous system and the digestive system - and is part of a group of
        diseases called amyloidosis.
      </Paragraph>
      <Paragraph>
        hATTR amyloidosis is classed as a rare genetic condition. However, it has been estimated
        that <b>1% of people in County Donegal region of North West Ireland</b> carry a mutation
        that can cause hATTR amyloidosis.
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            updateActiveAnchor(null);
            scrollToAnchor('references');
          }}>
          <sup>2</sup>
        </Link>
      </Paragraph>
      <LineBreak />
      <Note fontSize="1.625rem" lineHeight="1.231em" fontWeight="700" marginTop="5.3em">
        <p
          style={{
            fontSize: viewportWidth < 768 ? '0.875rem' : '1rem',
            fontWeight: 400,
            lineHeight: '1.6em'
          }}>
          Rosaline lives in Derry with extended family around Donegal and the northwest region of
          Ireland.
        </p>
        <p>
          Learn about Rosaline&apos;s story{' '}
          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault();
              updateActiveAnchor(null);
              scrollToAnchor('top');
              setTimeout(() => {
                navigate('/');
                handleCarousel('/');
                setTimeout(() => {
                  scrollToAnchor('rosalines-story');
                }, 580);
              }, 825);
            }}>
            here
          </Link>
        </p>
      </Note>
      <LineBreak customHeight="3.4em" />
      <SubSectionH3>hATTR amyloidosis affects multiple organs in the body, including:</SubSectionH3>
      <AffectedBodyParts>
        <AffectedBodyPart>
          <NerveIcon alt="hand" width="12.5em" />
          <Paragraph>
            The peripheral nervous system, which is made up of nerves that branch out from the brain
            and spinal cord and communicate with the rest of the body, including your arms and legs.
          </Paragraph>
          <LineBreak />
          <Paragraph>
            <b>Nerve damage is called polyneuropathy, resulting in improper function</b>
          </Paragraph>
        </AffectedBodyPart>
        <AffectedBodyPart>
          <HeartIcon alt="heart" width="12.5em" />
          <Paragraph>
            The cardiac system, which is made up of the heart and blood vessels, transports blood
            through veins and delivers oxygen to cells in the body.
          </Paragraph>
          <LineBreak />
          <Paragraph>
            <br />
            <b>Cardiomyopathy is a disease of the heart muscle that leads to heart failure</b>
          </Paragraph>
        </AffectedBodyPart>
        <AffectedBodyPart>
          <BrainIcon alt="brain" width="12.5em" />
          <Paragraph>
            The autonomic nervous system, which is made up of nerves that connect the brain and
            spinal cord to organs such as the heart, stomach, and intestines.
          </Paragraph>
          <LineBreak />
          <Paragraph>
            <br />
            <b>
              When the autonomic nervous system is not working correctly, it may affect involuntary
              bodily functions, such as breathing, digestion, and heart rate
            </b>
          </Paragraph>
        </AffectedBodyPart>
      </AffectedBodyParts>
      <ScrollPercentage
        as="div"
        onChange={(percentage) => checkIfSectionIsInView(percentage, '#symptoms')}>
        <SectionTitle id="symptoms">Symptoms</SectionTitle>
      </ScrollPercentage>
      <SubSectionH2>
        What are the symptoms of&nbsp;{viewportWidth >= 1110 && <br />}hATTR amyloidosis?
      </SubSectionH2>
      <Paragraph>
        The symptoms of hereditary ATTR (hATTR) amyloidosis can <b>vary among people</b> with the
        condition and even within families. Different symptoms may appear at different times for
        each person.
      </Paragraph>
      <Paragraph>
        The typical age for patients with the T80A (formerly known as T60A) mutation to{' '}
        <b>start showing symptoms is usually between 45 to 78, most often after the age of 60.</b>
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            updateActiveAnchor(null);
            scrollToAnchor('references');
          }}>
          <sup>3</sup>
        </Link>{' '}
        Since symptoms of hATTR amyloidosis can become more <b>severe over time</b>, it&apos;s
        important to talk to your doctor as soon as possible about any symptoms that you may be
        experiencing.
      </Paragraph>
      <SubSectionH3>The varying symptoms of hATTR amyloidosis</SubSectionH3>
      <CommonSymptomsWrapper>
        <CommonSymptoms backgroundColor="--amy-background-lightest-blue">
          <p style={{ marginBottom: '0.55em' }}>Autonomic nerve-related symptoms</p>
          <ul>
            <li>
              <span>&#183;</span>
              <p>
                Recurrent urinary tract
                <br />
                infections (UTIs)
              </p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Excessive sweating</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>
                Dizziness upon
                <br />
                standing
              </p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Sexual dysfunction</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Nausea and vomiting</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Diarrhoea</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Severe constipation</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Unintentional weight loss</p>
            </li>
          </ul>
        </CommonSymptoms>
        <CommonSymptoms backgroundColor="--amy-background-light-blue">
          <p>Peripheral nerve-related (polyneuropathy) symptoms</p>
          <ul>
            <li>
              <span>&#183;</span>
              <p>Tingling</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Numbness</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Carpal tunnel syndrome</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Burning pain</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>
                Loss of sensitivity to
                <br />
                temperature
              </p>
            </li>
            {/* <li>
              <span>&#183;</span>
              <p>Loss of movement control</p>
            </li> */}
            <li>
              <span>&#183;</span>
              <p>Weakness</p>
            </li>
          </ul>
        </CommonSymptoms>
        <CommonSymptoms backgroundColor="--amy-background-blue">
          <p>Heart-related (cardiomyopathy) symptoms</p>
          <ul>
            <li>
              <span>&#183;</span>
              <p>Fatigue</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Dizziness</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Shortness of breath</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Leg swelling (oedema)</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Chest pain</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Palpitations and abnormal heart rhythms (atrial fibrillation)</p>
            </li>
          </ul>
        </CommonSymptoms>
        <CommonSymptoms backgroundColor="--amy-background-dark-blue">
          <p>Other Symptoms</p>
          <ul>
            <li>
              <span>&#183;</span>
              <p>Glaucoma</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Blurred or spotty vision</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Abnormalities of the pupil or blood vessels on the white of the eye</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Detached retina</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Worsening dementia</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Stroke-like episodes</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Kidney dysfunction</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Headache</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Loss of movement control</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Seizures</p>
            </li>
            <li>
              <span>&#183;</span>
              <p>Weakness</p>
            </li>
          </ul>
        </CommonSymptoms>
      </CommonSymptomsWrapper>
      <Paragraph>
        <b>Note:</b> This is not a complete list of symptoms that may be experienced in patients
        with hATTR amyloidosis. Each patient has a different experience and you may not experience
        all of these symptoms, or you may not experience them at the same time. Symptoms of hATTR
        amyloidosis may become more severe over time.
      </Paragraph>
      <Note fontSize="1.625rem" lineHeight="1.231em" fontWeight="700" marginTop="5.25em">
        <p>
          More information about symptoms is{' '}
          <a href="https://www.hattrbridge.eu/about-hattr-amyloidosis/symptoms" target="__blank">
            available here.
          </a>
        </p>
      </Note>
      <LineBreak customHeight="4.05em" />
      <LargeImg
        sources={[
          data.nurseAndPatientSmilingMobile.childImageSharp.fluid,
          data.nurseAndPatientSmiling.childImageSharp.fluid
        ]}
        alt={data.nurseAndPatientSmiling.name}
      />
      <ScrollPercentage
        as="div"
        onChange={(percentage) => checkIfSectionIsInView(percentage, '#diagnosis')}>
        <SectionTitle id="diagnosis">Diagnosis</SectionTitle>
      </ScrollPercentage>
      <SubSectionH2>The path to diagnosis</SubSectionH2>
      <Paragraph>
        Symptoms of hATTR amyloidosis may be similar to those of other conditions. The more educated
        you are about the symptoms, the better prepared you can be to speak with your healthcare
        professional about a possible diagnosis.
      </Paragraph>
      <LineBreak />
      <Paragraph>
        <b>It is important that you discuss all the symptoms that you may be experiencing.</b> Your
        health care professional will most likely refer you to one or more specialists including
        neurologists, cardiologists, gastroenterologists and opthalmologists. This is because hATTR
        amyloidosis is a multi-systemic disease which can affect multiple parts of the body.
      </Paragraph>
      <SubSectionH3>How do you test for hATTR amyloidosis?</SubSectionH3>
      <Paragraph>
        Healthcare professionals will use a combination of tests to diagnose hATTR amyloidosis.
        These may include:
      </Paragraph>
      <LineBreak />
      <DiagnosisTests>
        <DiagnosisTest>
          <MicroscopeIcon alt="microscope" width="12.5em" />
          <p>
            <b>Tissue Biopsy</b>
          </p>
          <br />
          <p>
            By removing a small sample of tissue for lab examination, your doctors can confirm the
            presence of amyloid deposits.
          </p>
        </DiagnosisTest>
        <DiagnosisTest>
          <DnaIcon alt="dna" width="12.5em" />
          <p>
            <b>Genetic Testing</b>
          </p>
          <br />
          <p>
            This simple blood test will tell you if you carry a mutation in the TTR gene associated
            with hATTR amyloidosis. Genetic counsellors may be available to help you understand the
            issues related to genetic testing – including the medical, social and psychological
            consequences of a positive result – and can help you decide if the test is right for
            you.
          </p>
        </DiagnosisTest>
        <DiagnosisTest>
          <RibcageIcon alt="ribcage" width="12.5em" />
          <p>
            <b>Other Tests</b>
          </p>
          <br />
          <p>
            These can include nerve tests, muscle tests, echocardiogram, MRI and other types of
            scans. They can determine the impact of amyloid deposits on the heart, nerves and other
            organs.
          </p>
        </DiagnosisTest>
      </DiagnosisTests>
      <ScrollPercentage
        as="div"
        onChange={(percentage) => checkIfSectionIsInView(percentage, '#speaking-with-a-doctor')}>
        <SectionTitle id="speaking-with-a-doctor">Speaking with a doctor</SectionTitle>
      </ScrollPercentage>
      <SubSectionH2>
        Speaking with your healthcare professional about hATTR amyloidosis
      </SubSectionH2>
      <Paragraph>
        Symptoms of hATTR amyloidosis may be similar to those of other conditions. It’s important
        that you discuss all the symptoms you experience because your healthcare professional may
        choose to refer you to a specialist who could prescribe further tests to help make a
        diagnosis.
      </Paragraph>
      {/* <Note lineHeight="1.625em" marginTop="5.25em">
        <p style={{ fontSize: viewportWidth < 768 ? '1rem' : '1.25rem' }}>
          If you are in the UK, you should ask your doctor to refer you to the National
          Amyloidosis Centre (NAC) at the Royal Free Hospital, London, as soon as possible. You
          don&apos;t need to wait for a definite diagnosis of amyloidosis from your local doctor,
          or for determination of which type of amyloidosis you have. Just ask to be referred.
        </p>
      </Note> */}
      <LineBreak customHeight="3.95em" />
      <SubSectionH3>Learn more about</SubSectionH3>
      <LinksToLearnMore
        links={['/genetics-and-t80a-roots/', '/']}
        handleCarousel={handleCarousel}
        viewportWidth={viewportWidth}
        updateActiveAnchor={updateActiveAnchor}
      />
      <Footnotes>
        <span id="references">References</span>
        <br />
        1. Gillmore JD et al. Adv Ther. 2022;39(6):2292-2301
        <br />
        2. Reilly MM, Staunton H, Harding AE. Familial amyloid polyneuropathy (TTR Ala 60) in North
        West Ireland: a clinical, genetic, and epidemiological study. J Neurol Neurosurg Psychiatry.
        1995; 59:45–49.
        <br />
        3. Sattianayagam PT, Hahn AF, Whelan CJ, et al. Cardiac phenotype and clinical outcome of
        familial amyloid polyneuropathy associated with transthyretin alanine 60 variant. Eur Heart
        J. 2012; 33:1120–1127.
        <br />
        4. Adams D, Ando Y, Beirão JM, et al. Expert consensus recommendations to improve diagnosis
        of ATTR amyloidosis with polyneuropathy [published online ahead of print, 2020 Jan 6]. J
        Neurol. 2020;10.1007/s00415-019-09688-0.
        <br />
        <br />
        <span>Abbreviations</span>
        <br />
        ATTR: Transthyretin amyloidosis
        <br />
        hATTR: hereditary ATTR (hATTR)
        <br />
        MRI: magnetic resonance imaging
      </Footnotes>
    </section>
  );
};

const AffectedBodyParts = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: -0.425em;

  > div:first-of-type svg {
    margin: 1.875em auto 1.68em;
  }

  @media screen and (min-width: 75em) {
    flex-direction: row;
  }
`;

const AffectedBodyPart = styled.div`
  text-align: center;
  width: calc((100vw / 3) * 2);
  max-width: 22.5em;
  margin: 0 auto;

  svg {
    margin: 4.68em auto 1.68em;
  }

  p {
    font-size: 1rem;
  }

  @media screen and (min-width: 75em) {
    max-width: 15.625em;

    svg {
      margin: 1.875em auto 1.68em;
    }
  }
`;

const CommonSymptomsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.4em;

  @media screen and (min-width: 75em) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const CommonSymptoms = styled.div`
  background-color: var(${({ backgroundColor }) => backgroundColor});
  padding: 1.25em 1.522em 1.25em 1.522em;
  margin: 0 auto 1.25em;
  max-width: 21.563em;
  width: 100%;

  @media screen and (min-width: 75em) {
    max-width: 25em;
    margin: 0 0 1.25em;
  }

  p,
  ul {
    font-size: 1.25rem;
  }

  > p {
    margin-bottom: 0.5em;
    font-weight: 700;
  }

  ul {
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      line-height: 2em;

      p {
        line-height: 1.35em;
      }

      span {
        font-weight: 800;
        font-size: 1.25em;
        position: relative;
        bottom: 0.025em;
        margin-right: 0.425em;
      }
    }
  }

  @media screen and (min-width: 75em) {
    &:nth-child(1),
    &:nth-child(2) {
      height: 29em;
    }

    &:nth-child(3),
    &:nth-child(4) {
      height: 33.375em;
    }
  }

  &:nth-child(1) ul {
    li:nth-child(1),
    li:nth-child(3) {
      margin: 0.275em 0;
      span {
        align-self: flex-start;
        bottom: 0.275em;
      }
    }
  }

  &:nth-child(2) ul {
    li:nth-child(5) {
      margin: 0.275em 0;
      span {
        align-self: flex-start;
        bottom: 0.275em;
      }
    }
  }

  &:nth-child(3) ul {
    li:nth-child(6) {
      margin: 0.275em 0;
      span {
        align-self: flex-start;
        bottom: 0.275em;
      }
    }
  }

  &:nth-child(4) ul {
    li:nth-child(3) {
      margin: 0.275em 0;
      span {
        align-self: flex-start;
        bottom: 0.275em;
      }
    }
  }

  &:last-child {
    margin-bottom: 2em;
  }
`;

const DiagnosisTests = styled(AffectedBodyParts)`
  /* margin-bottom: 2.5em; */

  /* @media screen and (min-width: 75em) {
    margin-bottom: 4.45em;
  } */
`;

const DiagnosisTest = styled(AffectedBodyPart)`
  svg {
    margin: 3.6em auto 1.68em;
  }

  b {
    font-size: 1.25rem;
  }

  @media screen and (min-width: 75em) {
    svg {
      margin: 0.8em auto 1.68em !important;
    }
  }
`;

const Footnotes = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.75rem;
  margin-top: 8.167em;

  span {
    text-transform: uppercase;
    text-decoration: underline;
  }
`;

export const SymptomsAndDiagnosisQuery = graphql`
  {
    churchOnMistyMorningMobile: file(
      relativePath: { eq: "SymptomsAndDiagnosis/church_on_misty_morning_mobile.jpg" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 737, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    churchOnMistyMorning: file(
      relativePath: { eq: "SymptomsAndDiagnosis/church_on_misty_morning.jpg" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 820, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    nurseAndPatientSmilingMobile: file(
      relativePath: { eq: "SymptomsAndDiagnosis/nurse_and_patient_smiling_mobile.jpg" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 737, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    nurseAndPatientSmiling: file(
      relativePath: { eq: "SymptomsAndDiagnosis/nurse_and_patient_smiling.jpg" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 820, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default SymptomsAndDiagnosis;
